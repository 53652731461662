import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'

const httpService: HttpService = new HttpService()

export const getJobs = () => {
  const url = `https://${baseUrl()}/coachrail/v1/jobs`
  return httpService.get(url)
}

export const getUserJobs = (params) => {
  let query = ''
  const queryMap = new Map()
  if (params?.limit) {
    queryMap.set('limit', params.limit)
  }
  if (params?.offset) {
    queryMap.set('offset', params.offset)
  }
  if (queryMap.size) {
    query = `?${Array.from(queryMap)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`
  }
  const url = `https://${baseUrl()}/coachrail/v1/users/jobs${query}`
  return httpService.get(url)
}
