<template>
  <Sheet class="container">
    <v-flex xs12>
      <v-layout column>
        <v-flex xs12>
          <h1>Jobs Permissions</h1>
        </v-flex>
        <v-flex xs12 class="padding-x-10 padding-y-5">
          <v-layout row>
            <v-flex xs6>
              <h3>Jobs</h3>
              <v-data-table
                id="jobSearchJobsTable"
                :loading="loading"
                :headers="jobSearch.jobsTable.headers"
                :items="jobs"
                item-key="externalId"
                :pagination.sync="jobSearch.jobsTable.pagination"
              >
                <template #items="props">
                  <td>
                    <v-checkbox
                      :id="`job-select-${props.item.externalId}`"
                      hide-details
                      @change="selectJob(props.item)"
                    />
                  </td>
                  <td class="text-xs">
                    {{ props.item.externalId }}
                  </td>
                  <td class="text-xs">
                    {{ props.item.name }}
                  </td>
                </template>
              </v-data-table>
            </v-flex>
            <v-flex class="padding-l-5" xs6>
              <h3>Permissions</h3>
              <v-data-table
                id="jobSearchPermissionsTable"
                :loading="loading"
                :headers="jobSearch.permissionsTable.headers"
                :items="jobSearchMappedPermissions"
                item-key="permissionId"
                :pagination.sync="jobSearch.permissionsTable.pagination"
              >
                <template #items="props">
                  <td class="text-xs">
                    {{ props.item.permissionId }}
                  </td>
                  <td class="text-xs">
                    {{ props.item.permissionName }}
                  </td>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex class="padding-t-5" xs12>
      <v-layout column>
        <v-flex xs12 class="padding-x-10 padding-y-5">
          <v-layout class="align-center" row>
            <h2 class="border-0 line-height-48">Filter by User</h2>
            <v-flex class="padding-l-5">
              <CRInput
                v-model="userSearch.search"
                class="w-200"
                placeholder="User Name"
                type="text"
                hide-details
                :append-icon="userSearch.search ? 'clear' : 'search'"
                @click:append="userSearch.search = ''"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 class="padding-x-10 padding-y-5">
          <v-layout row>
            <v-flex xs6>
              <h3>Jobs</h3>
              <v-data-table
                id="jobsTable"
                :loading="loading"
                :headers="userSearch.jobsTable.headers"
                :items="filteredUserJobScopes"
                item-key="jobExternalId"
                :pagination.sync="userSearch.jobsTable.pagination"
              >
                <template #items="props">
                  <td>
                    <v-checkbox
                      :id="`user-select-${props.item.jobExternalId}`"
                      hide-details
                      @change="selectUser(props.item.userId)"
                    />
                  </td>
                  <td class="text-xs">
                    {{ props.item.userName }}
                  </td>
                  <td class="text-xs">
                    {{ props.item.jobExternalId }}
                  </td>
                  <td class="text-xs">
                    {{ !!props.item.global }}
                  </td>
                  <td class="text-xs">
                    {{ props.item.customerAccountName }}
                  </td>
                  <td class="text-xs">
                    {{ props.item.quoteId }}
                  </td>
                  <td class="text-xs">
                    {{ props.item.reservationId }}
                  </td>
                </template>
              </v-data-table>
            </v-flex>
            <v-flex class="padding-l-5" xs6>
              <h3>Permissions</h3>
              <v-data-table
                id="jobsTable"
                :loading="loading"
                :headers="userSearch.permissionsTable.headers"
                :items="userSearchMappedPermissions"
                item-key="permissionId"
                :pagination.sync="userSearch.permissionsTable.pagination"
              >
                <template #items="props">
                  <td class="text-xs">
                    {{ props.item.permissionId }}
                  </td>
                  <td class="text-xs">
                    {{ props.item.permissionName }}
                  </td>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </Sheet>
</template>

<script>
import { getJobs, getUserJobs } from '@/services/jobs'
import { mapActions } from 'vuex'
import Sheet from '@/layouts/Sheet.vue'

export default {
  components: {
    Sheet,
  },
  data() {
    return {
      loading: false,
      jobs: [],
      userJobScopes: [],
      jobsPermissionsMap: {},
      jobSearch: {
        selectedJobs: [],
        jobsTable: {
          pagination: {
            page: 1,
            rowsPerPage: 10,
            totalItems: 0,
          },
          headers: [
            {
              align: 'left',
              text: 'Select',
              sortable: false,
            },
            {
              align: 'left',
              text: 'Key',
              value: 'externalId',
            },
            {
              align: 'left',
              text: 'Name',
              value: 'name',
            },
          ],
        },
        permissionsTable: {
          pagination: {
            page: 1,
            rowsPerPage: 10,
            totalItems: 0,
          },
          headers: [
            {
              align: 'left',
              text: 'ID',
              value: 'permissionId',
            },
            {
              align: 'left',
              text: 'Name',
              value: 'permissionName',
            },
          ],
        },
      },
      userSearch: {
        search: '',
        selectedUsers: [],
        jobsTable: {
          pagination: {
            page: 1,
            rowsPerPage: 10,
            totalItems: 0,
          },
          headers: [
            {
              align: 'left',
              text: 'Select User',
              sortable: false,
            },
            {
              align: 'left',
              text: 'User',
              value: 'userName',
            },
            {
              align: 'left',
              text: 'Job Key',
              value: 'jobExternalId',
            },
            {
              align: 'left',
              text: 'Global',
              value: 'global',
            },
            {
              align: 'left',
              text: 'Customer Account',
              value: 'customerAccountName',
            },
            {
              align: 'left',
              text: 'Quote',
              value: 'quoteId',
              sortable: false,
            },
            {
              align: 'left',
              text: 'Reservation',
              value: 'reservationId',
              sortable: false,
            },
          ],
        },
        permissionsTable: {
          pagination: {
            page: 1,
            rowsPerPage: 10,
            totalItems: 0,
          },
          headers: [
            {
              align: 'left',
              text: 'ID',
              value: 'id',
            },
            {
              align: 'left',
              text: 'Key',
              value: 'key',
            },
            {
              align: 'left',
              text: 'Name',
              value: 'name',
            },
          ],
        },
      },
    }
  },
  computed: {
    jobSearchMappedPermissions() {
      const permissions = new Set()
      for (const job of this.jobSearch.selectedJobs) {
        if (!this.jobsPermissionsMap[job.externalId]?.length) {
          continue
        }
        permissions.add(...this.jobsPermissionsMap[job.externalId])
      }
      return Array.from(permissions)
    },
    filteredUserJobScopes() {
      if (!this.userSearch.search && !this.userSearch.selectedUsers.length) {
        return this.userJobScopes
      }
      const filteredUserJobScopes = this.userJobScopes.filter((user) =>
        user.userName
          .toLowerCase()
          .includes(this.userSearch.search.toLowerCase())
      )
      const selectedUserJobScopes = this.userJobScopes.filter((user) =>
        this.userSearch.selectedUsers.includes(user.userId)
      )
      return Array.from(
        new Set(filteredUserJobScopes.concat(selectedUserJobScopes))
      )
    },
    userSearchMappedPermissions() {
      const permissions = new Set()
      for (const userJobScope of this.filteredUserJobScopes) {
        if (this.jobsPermissionsMap[userJobScope.jobExternalId]?.length) {
          permissions.add(
            ...this.jobsPermissionsMap[userJobScope.jobExternalId]
          )
        }
      }
      return Array.from(permissions)
    },
  },
  async mounted() {
    await this.getJobs()
    await this.getUserJobs()
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    async getJobs() {
      this.loading = true
      try {
        const jobsData = await getJobs()
        this.jobs = jobsData.data
        for (const job of this.jobs) {
          this.jobsPermissionsMap[job.externalId] = job.permissions
        }
        this.loading = false
      } catch (e) {
        console.error(e)
        this.showAlert({
          type: 'error',
          message: 'Error getting jobs',
        })
        this.loading = false
      }
    },
    async getUserJobs() {
      this.loading = true
      try {
        const userJobsData = await getUserJobs()
        this.userJobScopes = userJobsData.data.rows
        this.loading = false
      } catch (e) {
        console.error(e)
        this.showAlert({
          type: 'error',
          message: 'Error getting user jobs',
        })
        this.loading = false
      }
    },
    selectJob(job) {
      if (this.jobSearch.selectedJobs.includes(job)) {
        this.jobSearch.selectedJobs = this.jobSearch.selectedJobs.filter(
          (selectedJob) => selectedJob !== job
        )
      } else {
        this.jobSearch.selectedJobs.push(job)
      }
    },
    selectUser(userId) {
      if (this.userSearch.selectedUsers.includes(userId)) {
        this.userSearch.selectedUsers = this.userSearch.selectedUsers.filter(
          (selectedUser) => selectedUser !== userId
        )
      } else {
        this.userSearch.selectedUsers.push(userId)
      }
    },
  },
}
</script>
